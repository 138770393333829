var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.MultistepForm,{attrs:{"stepData":_vm.stepData,"validate":_setup.validate},on:{"update:stepData":function($event){return _setup.emit('stepData', $event)},"change":_setup.handleChange,"submit":_setup.submit},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('form',{staticClass:"lnl-form",attrs:{"autocomplete":"off"}},[_c(_setup.LNLForm,_vm._b({attrs:{"region":_vm.region}},'LNLForm',props,false))],1),_c('div',{staticClass:"sidebar"},[_c('header',[_vm._v("Your Event Process")]),_c(_setup.ProgressList,{key:JSON.stringify(props.steps)},[_c(_setup.ListItem,{attrs:{"done":true,"label":"Event Type"}},[_vm._v(" LNL ")]),_c(_setup.ListItem,{attrs:{"label":"Event Details","done":props.steps.details.completed}},[_c('div',[_vm._v(_vm._s(props.getData('details', 'Address.address')))]),(props.getData('details', 'Address.city'))?_c('span',[_vm._v(_vm._s(props.getData('details', 'Address.city')))]):_vm._e(),(
              props.getData('details', 'Address.city') &&
              props.getData('details', 'Address.state')
            )?_c('span',[_vm._v(", "),(props.getData('details', 'Address.state'))?_c('span',[_vm._v(" "+_vm._s(props.getData('details', 'Address.state'))+" ")]):_vm._e()]):_vm._e(),(props.getData('details', 'Address.zipCode'))?_c('span',[_vm._v(_vm._s(props.getData('details', 'Address.zipCode')))]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_setup.formatDate( props.getData('details', 'FirstEvent'), props.getData('details', 'LastEvent') ))+" ")]),_c('div',[_vm._v(" "+_vm._s(_setup.formatTime( props.getData('details', 'StartTime'), props.getData('details', 'EndTime') ))+" "),(props.getData('details', 'Repeat'))?_c('p',{domProps:{"innerHTML":_vm._s(
                _setup.formatRepeat(
                  props.getData('details', 'Repeat'),
                  props.getData('details', 'FirstEvent'),
                  props.getData('details', 'LastEvent')
                )
              )}}):_vm._e()]),(
              _setup.has(_vm.stepData, 'details.data.Repeat') &&
              _vm.stepData.details.data?.Repeat === 'custom_recurrence'
            )?_c('span',[_vm._v(_vm._s(_setup.repeatInterval(props)))]):_vm._e(),(props.getData('details', 'EventTags'))?_c('span',[_vm._v("Tags: "+_vm._s(props.getData('details', 'EventTags').join(', ')))]):_vm._e()]),_c(_setup.ListItem,{attrs:{"label":"School","done":props.steps.school.completed}},[_vm._v(" "+_vm._s(props.getData('school', 'school.name'))+" ")]),_c(_setup.ListItem,{attrs:{"label":"Staff and Chapter","done":props.steps.staffAndChapter.completed}},[(props.getData('staffAndChapter', 'primaryStaff'))?_c('p',[_vm._v(" Staff: "+_vm._s(props.getData('staffAndChapter', 'primaryStaff.firstName'))+" "+_vm._s(props.getData('staffAndChapter', 'primaryStaff.lastName'))+" ")]):_vm._e(),(props.getData('staffAndChapter', 'chapter'))?_c('p',[_vm._v(" Chapter: "+_vm._s(props.getData('staffAndChapter', 'chapter.chapterName'))+" ")]):_vm._e()]),_c(_setup.ListItem,{attrs:{"label":"Thumbnail and Description","done":props.steps.thumbnailAndDescription.completed}},[_c('img',{attrs:{"src":props.getData('thumbnailAndDescription', 'thumbnail'),"height":"60","width":"60","alt":"image for LNL event"}}),(props.getData('thumbnailAndDescription', 'description'))?_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(props.getData('thumbnailAndDescription', 'description'))}})]):_vm._e()]),_c(_setup.ListItem,{attrs:{"label":"Options","done":props.steps.options.completed}},[(props.getData('options', 'eventTrack'))?_c('p',[_vm._v(" Track: "+_vm._s(props.getData('options', 'eventTrack').name)+" ")]):_vm._e(),(props.getData('options', 'hideFromWebAndMobile'))?_c('p',[_vm._v(" This event will be hidden from the website and all mobile apps ")]):_vm._e(),(
              _setup.isFutureEvent(
                props.getData('details', 'FirstEvent'),
                props.getData('details', 'StartTime'),
                props.getData('details', 'TimeZone')
              ) && props.getData('options', 'sendPushNotification')
            )?_c('p',[_vm._v(" Push notification will be send ")]):_vm._e(),(props.getData('options', 'isTYAEvent'))?_c('p',[_vm._v("This event is part of TYA")]):_vm._e(),(props.getData('options', 'isBGCubedEvent'))?_c('p',[_vm._v("This event is part of BG Cubed")]):_vm._e()])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }