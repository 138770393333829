import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=20f9154f&scoped=true&"
import script from "./Layout.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Layout.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Layout.vue?vue&type=style&index=0&id=20f9154f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f9154f",
  null
  
)

export default component.exports