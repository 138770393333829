import { render, staticRenderFns } from "./Shabbaton.vue?vue&type=template&id=4476d0b0&scoped=true&"
import script from "./Shabbaton.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Shabbaton.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Shabbaton.vue?vue&type=style&index=0&id=4476d0b0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4476d0b0",
  null
  
)

export default component.exports