import CommunityMemberProfile from './CommunityMemberProfile.vue';
import { compose, withData, withProps } from 'vue-compose';
import {
  createAddressEnhancer,
  updateEmailEnhancer,
  createEmailEnhancer,
  updatePhoneEnhancer,
  createPhoneEnhancer,
  updateAddressEnhancer,
  deletePhoneEnhancer
} from 'shared/enhancers/communityMembersEnhancer';
import { toCompactCommunityMember } from './utils';
import { CommunityMemberViewModel, Props } from './types';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';
import { useGetCommunityMemberProfileQuery } from 'shared/generated/graphql-types';
import { currentUserEnhancer } from 'shared/enhancers/currentUserEnhancer';
import { changePasswordEnhancer } from 'shared/enhancers/changePasswordEnhancer';

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

interface CommunityMemberProfileProps {
  communityMember: CommunityMemberViewModel | null;
  loadingCommunityMemberProfile: boolean;
}
const getCommunityMemberProfileEnhancer = wrapComponent<Props, CommunityMemberProfileProps>(
  (props) => {
    const { loading, result } = useGetCommunityMemberProfileQuery(
      computed(() => ({
        personID: props.personID
      }))
    );

    return computed(() => ({
      communityMember: result.value?.singleCommunityMember
        ? toCompactCommunityMember(result.value.singleCommunityMember)
        : undefined,
      loadingCommunityMemberProfile: loading.value
    }));
  }
);

export const enhancer = compose(
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  withData({
    showPasswordModal: {
      initialValue: false
    }
  }),
  getCommunityMemberProfileEnhancer,
  changePasswordEnhancer,
  updateEmailEnhancer,
  createEmailEnhancer,
  updatePhoneEnhancer,
  updateAddressEnhancer,
  createAddressEnhancer,
  createPhoneEnhancer,
  currentUserEnhancer,
  deletePhoneEnhancer
);

export default enhancer(CommunityMemberProfile);
